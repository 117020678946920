::selection {
  background-color: var(--pink);
}

body.purple {
  background-color: var(--purple);
}

body.pink {
  background-color: var(--pink);
}
body.pink ::selection {
  background-color: var(--green);
}

body.green {
  background-color: var(--green);
}

body.black {
  background-color: var(--black);
}

body.white {
  background-color: var(--white);
}
