.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  width: var(--closeButtonIcon);
  height: var(--closeButtonIcon);
  margin: var(--gapM);
  transition: color 0.3s ease-in-out;
  /* Vertical line */
  /* horizontal line */
}
.closeButton:before,
.closeButton:after {
  content: '';
  position: absolute;
  background-color: var(--black);
  transition: transform 0.25s ease-out;
}
.closeButton.black:before,
.closeButton.black:after,
.closeButton.purple:before,
.closeButton.purple:after {
  background-color: var(--white);
}
.closeButton:before {
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  margin-left: -1px;
  transform: rotate(45deg);
}
.closeButton:after {
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  margin-top: -1px;
  transform: rotate(45deg);
}
.closeButton:hover {
  cursor: pointer;
}
.closeButton:hover:before,
.closeButton:hover:after {
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}
.closeButton.black:hover:before,
.closeButton.black:hover:after,
.closeButton.purple:hover:before,
.closeButton.purple:hover:after {
  background-color: rgba(255, 255, 255, 0.5);
}
.closeButton:hover:before {
  transform: rotate(225deg);
}
.closeButton:hover:after {
  transform: rotate(225deg);
}
