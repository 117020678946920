.bubble {
  margin: var(--gapM);
  padding: var(--gapM) var(--gapL) var(--gapL) var(--gapL);
  /* padding: var(--gapL); */
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadowL);
}

.bubble.pink {
  background-color: var(--pink);
}

.bubble.green {
  background-color: var(--green);
}

.bubble.purple {
  background-color: var(--purple);
}
@media screen and (max-width: 500px) and (min-width: 470px) {
  /* force prettier line break for emojis title question */
  .bubble.purple {
    padding-right: 200px;
  }
}

.bubble.white {
  background-color: var(--white);
  box-shadow: var(--boxShadowLWhite);
}

.bubble.black {
  background-color: var(--black);
}
