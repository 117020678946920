html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: 'ABCGintoNormal-Regular-Trial'; */
  font-family: sans-serif;
  line-height: 1.1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
  overflow: hidden;
}

* {
  box-sizing: border-box;
  /* border: 1px solid black; */
}

*:focus {
  outline-color: var(--purple);
}

h1,
h2,
h3 {
  font-weight: inherit;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  margin: 0;
  padding: 0;
  font-family: inherit;
}

/* FONTS */
@font-face {
  font-family: 'tiny_5x3regular';
  src: url('../assets/fonts/TINY5x3GX.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'ABCGintoNord-UltraItalic-Trial';
  src: url('../assets/fonts/ABCGintoNord-UltraItalic-Trial.woff2')
      format('woff2'),
    url('../assets/fonts/ABCGintoNord-UltraItalic-Trial.woff') format('woff'),
    url('../assets/fonts/ABCGintoNord-UltraItalic-Trial.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABCGintoNormal-Regular-Trial';
  src: url('../assets/fonts/ABCGintoNormal-Regular-Trial.woff2') format('woff2'),
    url('../assets/fonts/ABCGintoNormal-Regular-Trial.woff') format('woff'),
    url('../assets/fonts/ABCGintoNormal-Regular-Trial.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABCGintoNormal-RegularItalic-Trial';
  src: url('../assets/fonts/ABCGintoNormal-RegularItalic-Trial.woff2')
      format('woff2'),
    url('../assets/fonts/ABCGintoNormal-RegularItalic-Trial.woff')
      format('woff'),
    url('../assets/fonts/ABCGintoNormal-RegularItalic-Trial.otf')
      format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABCGintoNormal-Bold-Trial';
  src: url('../assets/fonts/ABCGintoNormal-Bold-Trial.woff2') format('woff2'),
    url('../assets/fonts/ABCGintoNormal-Bold-Trial.woff') format('woff'),
    url('../assets/fonts/ABCGintoNormal-Bold-Trial.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABCGintoNormal-BoldItalic-Trial';
  src: url('../assets/fonts/ABCGintoNormal-BoldItalic-Trial.woff2')
      format('woff2'),
    url('../assets/fonts/ABCGintoNormal-BoldItalic-Trial.woff') format('woff'),
    url('../assets/fonts/ABCGintoNormal-BoldItalic-Trial.otf')
      format('opentype');
  font-weight: normal;
  font-style: normal;
}
