.openButton {
  transform-origin: center center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: var(--openButtonIcon);
  height: var(--openButtonIcon);
  margin: var(--gapM);
  transition: color 0.3s ease-in-out;
}

/* Vertical line */
/* horizontal line */
.openButton:before,
.openButton:after {
  content: '';
  position: absolute;
  background-color: var(--black);
  transition: transform 0.25s ease-out;
}
.openButton.black:before,
.openButton.black:after,
.openButton.purple:before,
.openButton.purple:after {
  background-color: var(--white);
}
.openButton:before {
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  margin-left: -1px;
  transform: rotate(90deg);
}
.openButton:after {
  top: 50%;
  left: -0.5px;
  width: 100%;
  height: 1px;
  margin-top: -0.5px;
  transform: rotate(90deg);
}

/* HOVER */
.openButton:hover {
  cursor: pointer;
}
.openButton:hover:before,
.openButton:hover:after {
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}
.openButton.open:hover:before,
.openButton.open:hover:after {
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.25);
}
.openButton.black:hover:before,
.openButton.black:hover:after,
.openButton.purple:hover:before,
.openButton.purple:hover:after {
  background-color: rgba(255, 255, 255, 0.5);
}
.openButton.black.open:hover:before,
.openButton.black.open:hover:after,
.openButton.purple.open:hover:before,
.openButton.purple.open:hover:after {
  background-color: rgba(255, 255, 255, 0.25);
}

/* DISPLAY MINUS ON OPEN */
.openButton.open:before {
  transform: rotate(270deg);
}
.openButton.open:after {
  transform: rotate(360deg);
}
